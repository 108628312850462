// extracted by mini-css-extract-plugin
export var aiChat__aiPowered = "bs_lP";
export var aiChat__animate = "bs_lD";
export var aiChat__animate_content = "bs_lF";
export var aiChat__answer = "bs_lL";
export var aiChat__content = "bs_lH";
export var aiChat__icon = "bs_lG";
export var aiChat__loadingState = "bs_lJ";
export var aiChat__message = "bs_lC";
export var aiChat__question = "bs_lK";
export var aiChat__sender = "bs_lM";
export var aiChat__senderInfo = "bs_lN";
export var pulseSize = "bs_lg";